<template>
  <v-container>
    <v-card class="mx-auto my-12" max-width="400">
      <v-card-title class="headline">Forgot Password</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForgotPassword" ref="form">
          <v-text-field
            v-model="email"
            label="Email"
            :rules="[rules.required, rules.email]"
            required
          ></v-text-field>
          <v-btn 
            type="submit" 
            :disabled="!isFormValid" 
            color="primary" 
            :loading="loading"
          >
            Send Reset Link
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" top>
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      loading: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      rules: {
        required: v => !!v || 'This field is required',
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      }
    };
  },
  computed: {
    isFormValid() {
      return this.email && this.rules.email(this.email) === true;
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    async submitForgotPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      
      this.loading = true;
      try {
        await this.forgotPassword(this.email);
        this.showSnackbar('Reset link sent. Please check your email.', 'success');
        setTimeout(() => this.$router.push('/'), 3000);
      } catch (error) {
        console.error('Error sending reset link', error);
        this.showSnackbar('Error sending reset link. Please try again.', 'error');
      } finally {
        this.loading = false;
      }
    },
    showSnackbar(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
};
</script>

<style scoped>
.v-card-title {
  font-size: 1.5rem;
}
</style>